









// components
import MenuDrawer from '@/components/MenuDrawer.vue'

// vue
import { Component, Vue } from 'vue-property-decorator'

// vuex
import { stateUtilsModule } from "@/store"
import UtilsModule from "@/store/UtilsModule"

@Component({
  components: {
    'menu-drawer' : MenuDrawer,
  }
})
export default class App extends Vue {
  stateUtilsModule: UtilsModule = stateUtilsModule

  get showNavBar() {
    if (this.$route.name !== 'HOME') {
      return true
    } else if (this.$route.name === 'HOME' && !stateUtilsModule.getHasShownLoadingAnimation) {
      return false
    } else {
      return true
    }
  }

  mounted() {
    // fix for mobile hover effects on iOS
    document.addEventListener('touchstart', () => {}, false)
  }
}
