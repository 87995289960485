import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import './quasar'

export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : 'https://chonkychx-nft-backend-rju6z.ondigitalocean.app' as const

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
