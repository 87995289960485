




































import { FAQItem } from '@/types/faq'

// vue
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ExpansionItem extends Vue {
  // props
  @Prop(Array) qData!: FAQItem
  @Prop(String) question!: string

}
