




































// types
import { BattleStatsFilterData } from '@/types/filters'

// vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// vuex
import { stateGallery, stateSearchFilters } from '@/store'
import SearchFiltersModule from '@/store/SearchFilters'

@Component
export default class FilterExpansionSlider extends Vue {
  @Prop(Object) filterData!: BattleStatsFilterData
  @Prop(String) icon!: string
  @Prop(String) title!: string

  // vuex
  stateSearchFilters: SearchFiltersModule = stateSearchFilters

  onChange() {
    stateGallery.action_onSearch()
  }
}
