// types
export type TierColor = { primary: string, secondary: string }

// vue
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'

@Module
export default class UtilsModule extends VuexModule {

  hasShownLoadingAnimation: boolean = false
  tierColors: {[key: number]: TierColor} = {
    1: { primary: '#4caf50', secondary: '#afffb2'}, // green
    2: { primary: '#2196F3', secondary: '#86c3fb'}, // blue
    3: { primary: '#FF9800', secondary: '#ffe59b'}, // orange
    4: { primary: '#9c27b0', secondary: '#ec7eff'}, // purple
    5: { primary: '#e91e63', secondary: '#ff8ab2'}, // pink
  }
  
  // GET -> return hasShownLoadingAnimation value
  get getHasShownLoadingAnimation(): boolean {
    return this.hasShownLoadingAnimation
  }

  // GET -> return color data from tier number
  get getTierColors(): (tierNum: number) => TierColor {
    return (tierNum: number) => {
      return this.tierColors[tierNum]
    }
  }

  // ---------------------------------------------
  //       set hasShownLoadingAnimation
  // ---------------------------------------------
  @Mutation
  setHasShownLoadingAnimation(val: boolean) {
    this.hasShownLoadingAnimation = val
  }
  @Action
  action_setHasShownLoadingAnimation(val: boolean) {
    this.setHasShownLoadingAnimation(val)
  }
}