// types
import { Chicken, ChickenList } from '@/types/chickens'

// utils
import { BASE_URL } from '@/main'
import axios from 'axios'

// vue
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import Vue from 'vue';

// vuex
import SearchFilters from './SearchFilters'

@Module
export default class Gallery extends VuexModule {

  private stateSearchFilters: SearchFilters;

  allMiniCards: ChickenList = {}
  chxDetailsLoading: boolean = false
  initLoadComplete: boolean = false
  noResults: boolean = false
  selectedChx: Chicken | null = null

  constructor(options: { store: any, name: string }, stateSearchFilters: SearchFilters) {
    super(options)
    this.stateSearchFilters = stateSearchFilters
  }

  // GET -> return chx details loading status
  get getchxDetailsLoading(): boolean {
    return this.chxDetailsLoading
  }

  // GET -> return all mini card data
  get getAllMiniCards(): ChickenList {
    return this.allMiniCards
  }

  // GET -> return selected chx
  get getSelectedChx(): Chicken | null {
    return this.selectedChx
  }

  // GET -> return no search results status
  get getNoResults(): boolean {
    return this.noResults
  }

  // GET -> return total num of mini cards
  get getTotalNumOfMinicards(): number {
    return Object.keys(this.allMiniCards).length
  }

  // GET -> return true if init load status
  get getInitLoadComplete(): boolean {
    return this.initLoadComplete
  }

  // ---------------------------------------------
  //             set init load boolean
  // ---------------------------------------------
  @Mutation
  setInitLoadComplete(val: boolean) {
    this.initLoadComplete = val
  }
  @Action
  action_setInitLoadComplete(val: boolean) {
    this.setInitLoadComplete(val)
  }

  // ---------------------------------------------
  //             set selected chicken
  // ---------------------------------------------
  @Mutation
  setSelectedChx(chx: Chicken | null) {
    Vue.set(this, 'selectedChx', chx ? { ...chx, FILE_NAME: `chx_${chx.FILE_NAME}.png`} : null)
  }
  @Action
  action_setSelectedChx(chx: Chicken | null) {
    this.setSelectedChx(chx)
  }

  // ---------------------------------------------
  //              set no results 
  // ---------------------------------------------
  @Mutation
  setNoResults(status: boolean) {
    this.noResults = status
  }

  // ---------------------------------------------
  //           set chx details loading
  // ---------------------------------------------
  @Mutation
  setChxDetailsLoading(status: boolean) {
    this.chxDetailsLoading = status
  }

  // ---------------------------------------------
  //             clear search data
  // ---------------------------------------------
  @Action
  async action_onClearBtn() {
    this.stateSearchFilters.action_clearActiveFilters()
    this.stateSearchFilters.action_clearAllCheckboxSelections()
    this.stateSearchFilters.action_clearAllBattleStatSelections()
    this.stateSearchFilters.action_setCurrentPaginationPage(1)
    await this.action_loadInitChxData()
  }

  // ---------------------------------------------
  //             clear MiniCards
  // ---------------------------------------------
  @Mutation
  clearAllMiniCards() {
    for (const miniCard in this.allMiniCards) {
      Vue.delete(this.allMiniCards, miniCard)
    }
  }

  // ---------------------------------------------
  //             create miniCards
  // ---------------------------------------------
  @Mutation
  createMiniCards(data: Chicken[]) {
    // takes in an array of chx results
    this.noResults = false
    data.forEach((chx: Chicken) => {
      Vue.set(this.allMiniCards, parseInt(chx.NUMBER), {
        ...chx,
        FILE_NAME: `chx_${chx.FILE_NAME}.png`,
      })
    })
  }

  // ---------------------------------------------
  //           axios.get first 500 chxs
  // ---------------------------------------------
  @Action
  async action_loadInitChxData() {
    this.clearAllMiniCards()
    axios(`${BASE_URL}/api/chickens`)
      .then((res: any) => {
        this.createMiniCards(res.data.chxResults)
        this.stateSearchFilters.action_setPaginationIds(res.data.paginationIds)
        this.action_setInitLoadComplete(true)
      })
      .catch((err) => {
        this.setNoResults(true)
        window.alert(`ERROR FETCHING INIT CHICKENS: ${err.message}`)
    })
  }

  // ---------------------------------------------
  //         axios.post fetch chxs by ids
  // ---------------------------------------------
  @Action
  async action_fetchChxsByIds(ids: string[]) {
    this.clearAllMiniCards()
    axios.post(`${BASE_URL}/api/chickens/byids`, ids)
      .then((res: any) => this.createMiniCards(res.data))
      .catch((err) => {
        this.setNoResults(true) 
        window.alert(`ERROR FETCHING CHICKENS BY IDS: ${err.message}`)
      })
  }

  // ---------------------------------------------
  //      axios.post fetch target chx by id
  // ---------------------------------------------
  @Action
  async action_fetchTargetChxById(id: string) {
    this.setChxDetailsLoading(true)
    axios.post(`${BASE_URL}/api/chickens/target`, id)
      .then((res: any) => {
        this.action_setSelectedChx(res.data)
        this.setChxDetailsLoading(false)
      })
      .catch((err) => window.alert(`ERROR FETCHING TARGET CHICKEN: ${err.message}`))
  }

  // ---------------------------------------------
  //    axios.post fetch chxs by search query
  // ---------------------------------------------
  @Action
  async action_onSearch() {
    // set current filter selections
    this.stateSearchFilters.action_setActiveFilters()
    // check if any filter selections have been made
    // if so, make server request
    if (this.stateSearchFilters.getFiltersAreActive) {
      this.clearAllMiniCards()
      axios.post(`${BASE_URL}/api/chickens/search`, this.stateSearchFilters.getActiveFilters)
        .then((res: any) => {
          if (!res.data.chxResults.length) this.setNoResults(true)
          else {
            this.createMiniCards(res.data.chxResults)
            this.stateSearchFilters.action_setPaginationIds(res.data.paginationIds)
          }
        })
        .catch((err) => window.alert(`ERROR SEARCHING FOR CHICKENS: ${err.message}`))
    } else {
      await this.action_loadInitChxData()
    }
  }
}