









// vue
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TitleLogo extends Vue {
  @Prop({ default: '' }) word1!: string
  @Prop({ default: '' }) word2!: string
  @Prop({ default: '' }) word3!: string
  @Prop({ default: '' }) word4!: string
}
