// data
import { battleStatsFilterData, checkboxFilterData } from '@/data/filterData'

// types
import { BattleStat, BattleStatsFilterData, BattleStatFilterItem, CheckboxFilterData, CheckboxFilterItem, FilterCategory } from '@/types/filters'

// vue
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'

@Module
export default class SearchFilters extends VuexModule {

  paginationIds: {[key: number]: string[]} = {
    1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [],
    11: [], 12: [], 13: [], 14: [], 15: [], 16: [], 17: [], 18: [], 19: [], 20: [],
  }
  
  // current pagination page
  currentPaginationPage: number = 1
  
  // filter data assinged from static file - filterData.ts
  battleStatsFilters: BattleStatsFilterData = battleStatsFilterData
  checkboxFilters: CheckboxFilterData = checkboxFilterData
  
  activeFilters: {[key in FilterCategory | BattleStat]: string[]} = {
    // checkboxes
    TIER_NUMBER: [],
    GENDER: [],
    CHICKEN_COLOR: [],
    BACKGROUND: [],
    EYES: [],
    NECKLACE: [],
    GLASSES: [],
    GLOW: [],
    SHOES: [],
    HAT: [],
    SKATEBOARD: [],
    SKATEBOARD_WHEEL_COLOR: [],
    CLASS_TYPE_1: [],
    CLASS_TYPE_2: [],
    ELEMENTAL_TYPE_1: [],
    ELEMENTAL_TYPE_2: [],
    MORAL_ALIGNMENT: [],
    // battle stats
    HEALTH: [],
    STRENGTH: [],
    DEFENSE: [],
    DEXTERITY: [],
    WISDOM: [],
    LUCK: [],
    CONSTITUTION: [],
    INTELLIGENCE: [],
    CHARISMA: [],
    WILLPOWER: [],
    MAGIC_ATTACK: [],
    MAGIC_DEFENSE: [],
  }

  // GET -> return max number of pagination pages
  get getPaginationMax(): number | void {
    for (const page in this.paginationIds) {

      if (!this.paginationIds[page].length) {
        return (parseInt(page) - 1)
      
      } else if (this.paginationIds[20].length) {
        return 20
      }
    }
  }

  // GET -> return current pagination page
  get getCurrentPaginationPage(): number {
    return this.currentPaginationPage
  }

  // GET -> return true if any search selections are active
  get getFiltersAreActive(): boolean {
    let active = false
    for (const filterCat in this.activeFilters) {
      if (this.activeFilters[filterCat as FilterCategory].length) {
        active = true
        break
      }
    }
    return active
  }

  // GET -> takes in a checkbox cat name and returns filter data for cat
  get getCheckboxFiltersByCat(): (catName: string) => CheckboxFilterItem {
    return (catName: string) => {
      return this.checkboxFilters[catName as FilterCategory]
    }
  }

  // GET -> takes in a battle stat name and returns filter data for that stat
  get getBattleStatFiltersByCat(): (statName: string) => BattleStatFilterItem {
    return (statName: string) => {
      return this.battleStatsFilters[statName as BattleStat]
    }
  }

  // GET -> takes a page num and returns pagination ids
  get getPaginationIdsByPageNum(): (pageNum: number) => string[] {
    return (pageNum: number) => {
      return this.paginationIds[pageNum]
    }
  }

  // GET -> returns activeFilters data
  get getActiveFilters(): {[key in FilterCategory | BattleStat]: string[]} {
    return this.activeFilters
  }

  // GET -> return number of search results
  get getSearchResultTotal(): number {
    let counter = 0
    for (const item in this.paginationIds) {
      counter += this.paginationIds[item].length
    }
    return counter
  }

  // ---------------------------------------------
  //             set paginationIds
  // ---------------------------------------------
  @Mutation
  setPaginationIds(ids: string[]) {
    Vue.set(this, 'paginationIds', ids)
  }
  @Action
  action_setPaginationIds(ids: string[]) {
    this.setPaginationIds(ids)
  }

  // ---------------------------------------------
  //          set currentPaginationPage
  // ---------------------------------------------
  @Mutation
  setCurrentPaginationPage(pageNum: number) {
    Vue.set(this, 'currentPaginationPage', pageNum)
  }
  @Action
  action_setCurrentPaginationPage(pageNum: number) {
    this.setCurrentPaginationPage(pageNum)
  }

  // ---------------------------------------------
  //              set activeFilters
  // ---------------------------------------------
  @Mutation
  setActiveFilters() {  
    // iterate through all checkbox filter categories
    for (const category in this.checkboxFilters) {
      // for each checkbox item, if the value is true
      // then push the item string into activeFilters array
      // at the corresponding key
      for (const item in this.checkboxFilters[category as FilterCategory]) {
        if (this.checkboxFilters[category as FilterCategory][item].checked) {
          this.activeFilters[category as FilterCategory].push(item)
        }
      } 
    }
    // iterate through all battle stat categories
    for (const battleStat in this.battleStatsFilters) {
      const min = this.battleStatsFilters[battleStat as BattleStat].value.min
      const max = this.battleStatsFilters[battleStat as BattleStat].value.max

      if (min !== 1 || max !== 10) {
        this.activeFilters[battleStat as BattleStat] = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ]
        this.activeFilters[battleStat as BattleStat] = this.activeFilters[battleStat as BattleStat].slice((min - 1), max)
      }
    }
  }
  @Action
  action_setActiveFilters() {
    this.action_clearActiveFilters()
    this.setActiveFilters()
  }

  // ---------------------------------------------
  //             clear activeFilters     
  // ---------------------------------------------
  @Mutation
  clearActiveFilters() {
    for (const category in this.activeFilters) {
      this.activeFilters[category as FilterCategory] = []
    }
  }
  @Action
  action_clearActiveFilters() {
    this.clearActiveFilters()
  }

  // ---------------------------------------------
  //          clear all checkbox selections
  // ---------------------------------------------
  @Mutation
  clearAllCheckboxSelections() {
    for (const filterCat in this.checkboxFilters) {
      for (const item in this.checkboxFilters[filterCat as FilterCategory]) {
        this.checkboxFilters[filterCat as FilterCategory][item].checked = false
      }
    }
  }
  @Action
  action_clearAllCheckboxSelections() {
    this.clearAllCheckboxSelections()
  }

  // ---------------------------------------------
  //       clear all battle stat selections
  // ---------------------------------------------
  @Mutation
  clearAllBattleStatSelections() {
    for (const battleStat in this.battleStatsFilters) {
      for (const item in this.battleStatsFilters[battleStat as BattleStat]) {
        this.battleStatsFilters[battleStat as BattleStat].value.min = 1
        this.battleStatsFilters[battleStat as BattleStat].value.max = 10
      }
    }
  }
  @Action
  action_clearAllBattleStatSelections() {
    this.clearAllBattleStatSelections()
  }
}