import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=0900d516&scoped=true&"
import script from "./Pagination.vue?vue&type=script&lang=ts&"
export * from "./Pagination.vue?vue&type=script&lang=ts&"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=0900d516&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0900d516",
  null
  
)

export default component.exports
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPagination});
