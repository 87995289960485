
















































// components
import Logo from '@/components/TitleLogo.vue'

// types
import { MenuItem } from '@/types/menu'

// vue
import { Component, Vue } from 'vue-property-decorator'

// vuex
import { stateGallery, stateSearchFilters } from '@/store'
import GalleryModule from '@/store/Gallery'
import SearchFiltersModual from '@/store/SearchFilters'

@Component({ 
  components: {
    'logo': Logo
  }
})
export default class MenuDrawer extends Vue {
  // data
  drawer: boolean = false

  // vuex
  stateGallery: GalleryModule = stateGallery
  stateSearchFilters: SearchFiltersModual = stateSearchFilters

  menuList: MenuItem[] = [
    {
      icon: 'agriculture',
      label: 'HOME',
      onClick: () => {
        if (this.$route.name !== 'HOME') this.$router.push('/')
      },
      separator: true
    },
    {
      icon: 'collections',
      label: 'GALLERY',
      onClick: () => {
        if (this.$route.name !== 'GALLERY') this.$router.push('/gallery')
      },
      separator: true
    },
    {
      icon: 'sports_kabaddi',
      label: 'PLAYING CHICKEN',
      onClick: () => {
        if (this.$route.name !== 'PLAYING CHICKEN') this.$router.push('/playing_chicken')
      },
      separator: true
    },
    {
      icon: 'favorite',
      label: 'MATCHMAKER',
      onClick: () => {
        if (this.$route.name !== 'MATCHMAKER') this.$router.push('/matchmaker')
      },
      separator: true
    },
  ]
}
