














// types
import { Chicken } from '@/types/chickens'

// utils
import { BASE_URL } from '@/main'

// vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// vuex
import { stateGallery } from '@/store'
import GalleryModual from '@/store/Gallery'

@Component
export default class MiniCard extends Vue {
  @Prop(Object) data!: Chicken

  // data
  BASE_URL = BASE_URL

  // vuex
  stateGallery: GalleryModual = stateGallery

  onClick() {
    this.$router.push(`/chicken/${this.data.NUMBER}`)
  }
}
