// types
import { BattleStatsFilterData, CheckboxFilterData } from '@/types/filters'

export const checkboxFilterData: CheckboxFilterData = {
  TIER_NUMBER: {
    '1': { label: 'Common Chicken', qColor: 'blue', checked: false },
    '2': { label: 'Special Chicken', qColor: 'cyan', checked: false },
    '3': { label: 'Rare Chicken', qColor: 'green', checked: false },
    '4': { label: 'Champion Chicken', qColor: 'yellow', checked: false },
    '5': { label: 'Legendary Chicken', qColor: 'orange', checked: false },
  },
  GENDER: {
    'MALE': { label: 'Male', qColor: 'blue-6', checked: false },
    'FEMALE': { label: 'Female', qColor: 'purple-13', checked: false },
    'YES': { label: 'Yes', qColor: 'green-6', checked: false },
  },
  CHICKEN_COLOR: {
    'CHX_COLOR_WHITE': { label: 'White', qColor: 'grey-4', checked: false },
    'CHX_COLOR_BLACK': { label: 'Black', qColor: 'grey-10', checked: false },
    'CHX_COLOR_RED': { label: 'Red', qColor: 'red', checked: false },
    'CHX_COLOR_LIGHT_ORANGE': { label: 'Light Orange', qColor: 'orange-3', checked: false },
    'CHX_COLOR_YELLOW': { label: 'Yellow', qColor: 'yellow', checked: false },
    'CHX_COLOR_BROWN': { label: 'Brown', qColor: 'brown', checked: false },
    'CHX_COLOR_ZOMBIE': { label: 'Zombie', qColor: 'green-3', checked: false },
    'CHX_COLOR_GREEN': { label: 'Green', qColor: 'green', checked: false },
    'CHX_COLOR_DARK_GREEN': { label: 'Dark Green', qColor: 'green-10', checked: false },
    'CHX_COLOR_TEAL': { label: 'Teal', qColor: 'teal', checked: false },
    'CHX_COLOR_DARK_BLUE': { label: 'Dark Blue', qColor: 'blue-10', checked: false },
    'CHX_COLOR_DARK_PURPLE': { label: 'Dark Purple', qColor: 'purple-8', checked: false },
    'CHX_COLOR_LIGHT_PINK': { label: 'Light Pink', qColor: 'pink-3', checked: false },
  },
  GLOW: {
    'GLOW_NONE': { label: 'No Glow', qColor: 'grey-5', checked: false },
    'GLOW': { label: 'Glow', qColor: 'yellow-2', checked: false },
  },
  BACKGROUND: {
    'BG_RED': { label: 'Red', qColor: 'red', checked: false },
    'BG_YELLOW': { label: 'Yellow', qColor: 'yellow', checked: false },
    'BG_GREEN': { label: 'Green', qColor: 'green', checked: false },
    'BG_TEAL': { label: 'Teal', qColor: 'teal', checked: false },
    'BG_BLUE': { label: 'Blue', qColor: 'blue-14', checked: false },
    'BG_PINK': { label: 'Pink', qColor: 'pink', checked: false },
    'BG_RED_BARN': { label: 'Red Barn', qColor: 'red', checked: false },
    'BG_BROWN_BARN': { label: 'Brown Barn', qColor: 'brown-6', checked: false },
    'BG_GREEN_BARN': { label: 'Green Barn', qColor: 'green-10', checked: false },
    'BG_OUTSIDE_SUN': { label: 'Outside Sun', qColor: 'yellow', checked: false },
    'BG_STORMY_FIELD': { label: 'Stormy Field', qColor: 'blue-grey-5', checked: false },
    'BG_RAINBOW': { label: 'Rainbow', qColor: 'red-14', checked: false },
    'BG_MOONLIT_FIELD': { label: 'Moonlit Field', qColor: 'purple-9', checked: false },
    'BG_SNOW': { label: 'Snow', qColor: 'cyan-11', checked: false },
    'BG_FIRE': { label: 'Fire', qColor: 'orange-10', checked: false },
    'BG_SKATEBOARD': { label: 'Skateboard', qColor: 'blue-14', checked: false },
    'BG_CLASS': { label: 'Class', qColor: 'red-14', checked: false },    
    'BG_CONCERT': { label: 'Concert', qColor: 'blue-14', checked: false },
    'BG_LIBRARY': { label: 'Library', qColor: 'brown-6', checked: false },    
    'BG_OFFICE': { label: 'Office', qColor: 'grey-5', checked: false },
    'BG_FACTORY': { label: 'Factory', qColor: 'grey-9', checked: false },
    'BG_HAUNTED_MANSION': { label: 'Haunted Mansion', qColor: 'purple-13', checked: false },
    'BG_WESTERN_SALOON': { label: 'Western Saloon', qColor: 'yellow-3', checked: false },
    'BG_BEACH': { label: 'Beach', qColor: 'blue-13', checked: false },
    'BG_UNDERWATER': { label: 'Underwater', qColor: 'blue-13', checked: false },
    'BG_SPACE_NO_MOON': { label: 'Space', qColor: 'grey-9', checked: false },
    'BG_SPACE_WITH_MOON': { label: 'Space Moon', qColor: 'grey-9', checked: false },
    'BG_MOON': { label: 'On The Moon', qColor: 'grey-9', checked: false },
    'BG_ALIEN_BLUE_TEAL': { label: 'Blue Alien Planet', qColor: 'light-blue-11', checked: false },
    'BG_ALIEN_PINK_YELLOW': { label: 'Yellow Alien Planet', qColor: 'purple-12', checked: false },
    'BG_ALIEN_TEAL_PINK': { label: 'Pink Alien Planet', qColor: 'light-blue-2', checked: false },
    'BG_PURPLE_VORTEX_2': { label: 'Purple Aura', qColor: 'purple-9', checked: false },
    'BG_RED_VORTEX_2': { label: 'Red Aura', qColor: 'red-10', checked: false },
    'BG_TEAL_VORTEX_2': { label: 'Teal Aura', qColor: 'teal-3', checked: false },
    'BG_YELLOW_VORTEX_2': { label: 'Yellow Aura', qColor: 'yellow-6', checked: false },
    'BG_GREEN_VORTEX': { label: 'Green Vortex', qColor: 'green-9', checked: false },
    'BG_RED_VORTEX': { label: 'Red Vortex', qColor: 'red-10', checked: false },
    'BG_BLUE_VORTEX': { label: 'Blue Vortex', qColor: 'blue-7', checked: false },
    'BG_PINK_VORTEX': { label: 'Pink Vortex', qColor: 'purple-12', checked: false },
  },
  EYES: {
    'EYES_NONE': { label: 'Regular', qColor: 'grey-5', checked: false},
    'EYES_ANIME': { label: 'Anime', qColor: 'blue', checked: false },
    'EYES_ANGRY': { label: 'Angry', qColor: 'red-5', checked: false },
    'EYES_HAPPY': { label: 'Happy', qColor: 'yellow', checked: false },
    'EYES_ETH' : { label: 'ETH', qColor: 'purple', checked: false },
    'EYES_BTC': { label: 'BTC', qColor: 'red', checked: false },
  },
  NECKLACE: {
    'NECKALCE_NONE': {label:"No Necklace", qColor: 'grey-5', checked: false},
    'NECKLACES_RED_TIE': { label: 'Red Tie', qColor: 'red', checked: false },
    'NECKLACES_GOLD_NECKLACE_1': { label: 'Gold Necklace 1', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_NECKLACE_2': { label: 'Gold Necklace 2', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_NECKLACE_1_BTC_MEDAL': { label: 'BTC Gold Necklace 1', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_NECKLACE_2_BTC_MEDAL': { label: 'BTC Gold Necklace 2', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_NECKLACE_1_ETH_MEDAL': { label: 'ETH Gold Necklace 1', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_NECKLACE_2_ETH_MEDAL': { label: 'ETH Gold Necklace 2', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_NECKLACE_1_USD_MEDAL': { label: 'USD Gold Necklace 1', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_NECKLACE_2_USD_MEDAL': { label: 'USD Gold Necklace 2', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_CLOCK_1': { label: 'Gold Clock Necklace 1', qColor: 'yellow-7', checked: false },
    'NECKLACES_GOLD_CLOCK_2': { label: 'Gold Clock Necklace 2', qColor: 'yellow-7', checked: false },
  },
  GLASSES: {
    'SUNGLASSES_NONE': { label: 'No Sunglasses', qColor: 'grey-5', checked: false},
    'SUNGLASSES_NERD': { label: 'Nerd', qColor: 'red', checked: false },
    'SUNGLASSES_DEALWITHIT': { label: 'Deal With It', qColor: 'deep-orange-14', checked: false },
    'SUNGLASSES_GOLD': { label: 'Gold', qColor: 'yellow-7', checked: false },
    'SUNGLASSES_GOLD_MONOCLE': { label: 'Gold Monocle', qColor: 'yellow-7', checked: false },
    'SUNGLASSES_KANYE_LIGHT_GREEN': { label: 'Kanye Light Green', qColor: 'light-green-4', checked: false },
    'SUNGLASSES_KANYE_BLUE': { label: 'Kanye Blue', qColor: 'blue', checked: false },
    'SUNGLASSES_KANYE_PURPLE': { label: 'Kanye Purple', qColor: 'purple', checked: false },
    'SUNGLASSES_KANYE_PINK': { label: 'Kanye Pink', qColor: 'pink', checked: false },
    'SUNGLASSES_KANYE_RED': { label: 'Kanye Red', qColor: 'red', checked: false },
    'SUNGLASSES_AVIATOR': { label: 'Aviator Black', qColor: 'grey-10', checked: false },
    'SUNGLASSES_AVIATOR_LIGHT_BLUE': { label: 'Aviator Light Blue', qColor: 'light-blue-11', checked: false },
    'SUNGLASSES_AVIATOR_RED': { label: 'Aviator Red', qColor: 'red', checked: false },
    'SUNGLASSES_AVIATOR_DARK_GREEN': { label: 'Aviator Dark Green', qColor: 'green-10', checked: false },
    'SUNGLASSES_AVIATOR_LIGHT_GREEN': { label: 'Aviator Light Green', qColor: 'light-green-12', checked: false },
    'SUNGLASSES_AVIATOR_TEAL': { label: 'Aviator Teal', qColor: 'teal', checked: false },
    'SUNGLASSES_AVIATOR_PINK': { label: 'Aviator Pink', qColor: 'pink', checked: false },
    'SUNGLASSES_AVIATOR_PURPLE': { label: 'Aviator Purple', qColor: 'purple', checked: false },
    'SUNGLASSES_EYE_PATCH': { label: 'Pirate Eye Patch', qColor: 'grey-10', checked: false },      
  },
  SHOES: {
    'SHOES_NONE': { label: 'No Shoes', qColor: 'grey-5', checked: false},
    'SHOES_BLACK': { label: 'Black', qColor: 'grey-10', checked: false },
    'SHOES_WHITE': { label: 'White', qColor: 'grey-5', checked: false },
    'SHOES_RED': { label: 'Red', qColor: 'red', checked: false },
    'SHOES_GREEN': { label: 'Green', qColor: 'green', checked: false },
    'SHOES_DARK_GREEN': { label: 'Dark Green', qColor: 'green-10', checked: false },
    'SHOES_BLUE': { label: 'Blue', qColor: 'blue', checked: false },
    'SHOES_DARK_BLUE': { label: 'Dark Blue', qColor: 'blue-10', checked: false },
    'SHOES_PURPLE': { label: 'Purple', qColor: 'purple', checked: false },
    'SHOES_PINK': { label: 'Pink', qColor: 'pink', checked: false },
    'SHOES_DARK_GREEN_BOOTS': { label: 'Dark Green Boots', qColor: 'green-10', checked: false },
    'SHOES_BLUE_BOOTS': { label: 'Blue Boots', qColor: 'blue', checked: false },
    'SHOES_RED_BOOTS': { label: 'Red Boots', qColor: 'red', checked: false },
    'SHOES_TEAL_BOOTS': { label: 'Teal Boots', qColor: 'teal', checked: false },
    'SHOES_RED_HEELS': { label: 'Red Heels', qColor: 'red', checked: false },
    'SHOES_PEG_LEG': { label: 'Pirate Peg Leg', qColor: 'brown', checked: false }, 
    'SHOES_SPEED_RED': { label: 'Red Speed Shoes', qColor: 'red', checked: false },    
    'SHOES_COWBOY_BOOTS': { label: 'Cowboy Boots', qColor: 'brown', checked: false },
    'SHOES_MOON_BOOTS': { label: 'Moon Boots', qColor: 'grey-4', checked: false },
    'SHOES_UNDERWATER_FINS': { label: 'Water Flippers', qColor: 'pink', checked: false },
  },
  HAT: {
    'HAT_NONE': { label: 'No Hat', qColor:'grey-5', checked: false},
    'HAT_TOP': { label: 'Top Hat', qColor: 'grey-10', checked: false},
    'HAT_PINK_BOW': { label: 'Pink Bow', qColor: 'pink', checked: false },
    'HAT_MINER': { label: 'Miner Helmet', qColor: 'yellow', checked: false },
    'HAT_BUNNY_EARS': { label: 'Bunny Ears', qColor: 'pink', checked: false },
    'HAT_BIRTHDAY': { label: 'Birthday Hat', qColor: 'teal', checked: false },
    'HAT_IRISH': { label: 'Lucky Irish Top Hat', qColor: 'green', checked: false },
    'HAT_ORANGE_HEADBAND': { label: 'Orange Headband', qColor: 'orange-3', checked: false },
    'HAT_BLUE_HEADBAND': { label: 'Blue Headband', qColor: 'blue', checked: false },
    'HAT_RED_HEADBAND': { label: 'Red Headband', qColor: 'red', checked: false },
    'HAT_PINK_HEADBAND': { label: 'Pink Headband', qColor: 'pink', checked: false },
    'HAT_PURPLE_HEADBAND': { label: 'Purple Headband', qColor: 'purple', checked: false },
    'HAT_SNORKLE': { label: 'Snorkle', qColor: 'pink', checked: false },
    'HAT_SOMBRERO': { label: 'Sombrero', qColor: 'yellow', checked: false },
    'HAT_SANTA': { label: 'Santa', qColor: 'red', checked: false },
    'HAT_COWBOY': { label: 'Cowboy', qColor: 'brown', checked: false },
    'HAT_HORNS_AND_PITCHFORK': { label: 'Devil Horns', qColor: 'red', checked: false },
    'HAT_QUEENS_GUARD': { label: 'Queens Guard', qColor: 'grey-10', checked: false },
    'HAT_PUMPKIN': { label: 'Pumpkin', qColor: 'orange-3', checked: false },
    'HAT_JESTER': { label: 'Jester', qColor: 'green', checked: false },
    'HAT_PROPELLER': { label: 'Propeller Cap', qColor: 'red', checked: false },
    'HAT_CROWN': { label: 'Crown', qColor: 'yellow', checked: false },
    'HAT_WIZARD_BEARD': { label: 'Wizard Hat And Beard', qColor: 'light-blue-11', checked: false },
    'HAT_WITCH': { label: 'Witch', qColor: 'purple', checked: false },
    'HAT_PIRATE': { label: 'Pirate', qColor: 'grey-10', checked: false },     
    'HAT_BONNET_PINK_RED': { label: 'Pink And Red Bonnet', qColor: 'pink', checked: false },
    'HAT_BONNET_TEAL_PINK': { label: 'Teal And Pink Bonnet', qColor: 'teal', checked: false },
    'HAT_BONNET_YELLOW_TEAL': { label: 'Yellow And Teal Bonnet', qColor: 'yellow', checked: false },
    'HAT_BONNET_RED_YELLOW': { label: 'Red And Yellow Bonnet', qColor: 'red', checked: false },
    'HAT_CONICAL': { label: 'Conical', qColor: 'yellow', checked: false },
    'HAT_AIR_HELMET': { label: 'Space Helmet', qColor: 'grey-4', checked: false },
    'HAT_CAP_WHITE': { label: 'White Baseball Cap', qColor: 'grey-4', checked: false },
    'HAT_CAP_BACKWARDS_WHITE': { label: 'Backwards White Baseball Cap', qColor: 'grey-4', checked: false },
    'HAT_CAP_BLACK': { label: 'Black Baseball Cap', qColor: 'grey-10', checked: false },
    'HAT_CAP_BACKWARDS_BLACK': { label: 'Backwards Black Baseball Cap', qColor: 'grey-10', checked: false },
    'HAT_CAP_RED': { label: 'Red Baseball Cap', qColor: 'red', checked: false },
    'HAT_CAP_BACKWARDS_RED': { label: 'Backwards Red Baseball Cap', qColor: 'red', checked: false },
    'HAT_CAP_BROWN': { label: 'Brown Baseball Cap', qColor: 'brown', checked: false },
    'HAT_CAP_BACKWARDS_BROWN': { label: 'Backwards Brown Baseball Cap', qColor: 'brown', checked: false },
    'HAT_CAP_YELLOW': { label: 'Yellow Baseball Cap', qColor: 'yellow', checked: false },
    'HAT_CAP_BACKWARDS_YELLOW': { label: 'Backwards Yellow Baseball Cap', qColor: 'yellow', checked: false },
    'HAT_CAP_GREEN': { label: 'Green Baseball Cap', qColor: 'green', checked: false },
    'HAT_CAP_BACKWARDS_GREEN': { label: 'Backwards Green Baseball Cap', qColor: 'green', checked: false },
    'HAT_CAP_TEAL': { label: 'Teal Baseball Cap', qColor: 'teal', checked: false },
    'HAT_CAP_BACKWARDS_TEAL': { label: 'Backwards Teal Baseball Cap', qColor: 'teal', checked: false },
    'HAT_CAP_BLUE': { label: 'Blue Baseball Cap', qColor: 'blue', checked: false },
    'HAT_CAP_BACKWARDS_BLUE': { label: 'Backwards Blue Baseball Cap', qColor: 'blue', checked: false },
    'HAT_CAP_PURPLE': { label: 'Purple Baseball Cap', qColor: 'purple', checked: false },
    'HAT_CAP_BACKWARDS_PURPLE': { label: 'Backwards Purple Baseball Cap', qColor: 'purple', checked: false },
    'HAT_CAP_PINK': { label: 'Pink Baseball Cap', qColor: 'pink', checked: false },
    'HAT_CAP_BACKWARDS_PINK': { label: 'Backwards Pink Baseball Cap', qColor: 'pink', checked: false },
    'HELMET_BLACK': { label: 'Black Helmet', qColor: 'grey-10', checked: false },
    'HELMET_RED': { label: 'Red Helmet', qColor: 'red', checked: false },
    'HELMET_LIGHT_GREEN': { label: 'Light Green Helmet', qColor: 'light-green-4', checked: false },
    'HELMET_DARK_GREEN': { label: 'Dark Green Helmet', qColor: 'green', checked: false },
    'HELMET_TEAL': { label: 'Teal Helmet', qColor: 'teal', checked: false },
    'HELMET_LIGHT_BLUE': { label: 'Light Blue Helmet', qColor: 'light-blue-11', checked: false },
    'HELMET_DARK_BLUE': { label: 'Dark Blue Helmet', qColor: 'blue', checked: false },
    'HELMET_PINK': { label: 'Pink Helmet', qColor: 'pink', checked: false },
    'HELMET_PURPLE': { label: 'Purple Helmet', qColor: 'purple', checked: false },
  },
  SKATEBOARD: {
    'BOARD_COLOR_RED': { label: 'Red Skateboard', qColor: 'red', checked: false},
    'BOARD_COLOR_DARK_RED': { label: 'Dark Red Skateboard', qColor: 'red-10', checked: false },
    'BOARD_COLOR_YELLOW': { label: 'Yellow Skateboard', qColor: 'yellow', checked: false },
    'BOARD_COLOR_BROWN': { label: 'Brown Skateboard', qColor: 'brown', checked: false },
    'BOARD_COLOR_GREEN': { label: 'Green Skateboard', qColor: 'green', checked: false },
    'BOARD_COLOR_DARK_GREEN': { label: 'Dark Green Skateboard', qColor: 'green-10', checked: false },
    'BOARD_COLOR_TEAL': { label: 'Teal Skateboard', qColor: 'teal', checked: false },
    'BOARD_COLOR_LIGHT_BLUE': { label: 'Light Blue Skateboard', qColor: 'light-blue-11', checked: false },
    'BOARD_COLOR_BLUE': { label: 'Blue Skateboard', qColor: 'blue', checked: false },
    'BOARD_COLOR_PINK': { label: 'Pink Skateboard', qColor: 'pink', checked: false },
  },
  SKATEBOARD_WHEEL_COLOR: {
    'BOARD_WHEEL_RED': { label: 'Red Wheels', qColor: 'red', checked: false },
    'BOARD_WHEEL_DARK_RED': { label: 'Dark Red Wheels', qColor: 'red-10', checked: false },
    'BOARD_WHEEL_GREEN': { label: 'Green Wheels', qColor: 'green', checked: false },
    'BOARD_WHEEL_DARK_GREEN': { label: 'Dark Green Wheels', qColor: 'green-10', checked: false },
    'BOARD_WHEEL_TEAL': { label: 'Teal Wheels', qColor: 'teal', checked: false },
    'BOARD_WHEEL_LIGHT_BLUE': { label: 'Light Blue Wheels', qColor: 'light-blue-11', checked: false },
    'BOARD_WHEEL_BLUE': { label: 'Blue Wheels', qColor: 'blue', checked: false },
    'BOARD_WHEEL_PINK': { label: 'Pink Wheels', qColor: 'pink', checked: false },
  },
  CLASS_TYPE_1: {
    'FIGHTER': { label: 'Fighter', qColor: 'blue-6', checked: false },
    'ROGUE': { label: 'Rogue', qColor: 'purple-13', checked: false },
    'CLERIC': { label: 'Cleric', qColor: 'green-6', checked: false },
    'WIZARD': { label: 'Wizard', qColor: 'blue-6', checked: false },
    'PALADIN': { label: 'Paladin', qColor: 'purple-13', checked: false },
    'RANGER': { label: 'Ranger', qColor: 'green-6', checked: false },
    'WARLOCK': { label: 'Warlock', qColor: 'blue-6', checked: false },
    'WARLORD': { label: 'Warlord', qColor: 'purple-13', checked: false },
    'AVENGER': { label: 'Avenger', qColor: 'green-6', checked: false },
    'BARBARIAN': { label: 'Barbarian', qColor: 'blue-6', checked: false },
    'BARD': { label: 'Bard', qColor: 'purple-13', checked: false },
    'DRUID': { label: 'Druid', qColor: 'green-6', checked: false },
    'INVOKER': { label: 'Invoker', qColor: 'blue-6', checked: false },
    'SHAMAN': { label: 'Shaman', qColor: 'purple-13', checked: false },
    'SORCERER': { label: 'Sorcerer', qColor: 'green-6', checked: false },
    'WARDEN': { label: 'Warden', qColor: 'blue-6', checked: false },
    'MONK': { label: 'Monk', qColor: 'purple-13', checked: false },
    'ASSASSIN': { label: 'Assassin', qColor: 'green-6', checked: false },
    'VAMPIRE': { label: 'Vampire', qColor: 'green-6', checked: false },
    'ZOMBIE': { label: 'Zombie', qColor: 'green-6', checked: false },
  },
  CLASS_TYPE_2: {
    'NONE': { label: 'None', qColor: 'grey-5', checked: false },
    'PALADIN': { label: 'Paladin', qColor: 'purple-13', checked: false },
    'RANGER': { label: 'Ranger', qColor: 'green-6', checked: false },
    'WARLOCK': { label: 'Warlock', qColor: 'blue-6', checked: false },
    'WARLORD': { label: 'Warlord', qColor: 'purple-13', checked: false },
    'AVENGER': { label: 'Avenger', qColor: 'green-6', checked: false },
    'BARBARIAN': { label: 'Barbarian', qColor: 'blue-6', checked: false },
    'BARD': { label: 'Bard', qColor: 'purple-13', checked: false },
    'DRUID': { label: 'Druid', qColor: 'green-6', checked: false },
    'INVOKER': { label: 'Invoker', qColor: 'blue-6', checked: false },
    'SHAMAN': { label: 'Shaman', qColor: 'purple-13', checked: false },
    'SORCERER': { label: 'Sorcerer', qColor: 'green-6', checked: false },
    'WARDEN': { label: 'Warden', qColor: 'blue-6', checked: false },
    'MONK': { label: 'Monk', qColor: 'purple-13', checked: false },
    'ASSASSIN': { label: 'Assassin', qColor: 'green-6', checked: false },
    'VAMPIRE': { label: 'Vampire', qColor: 'purple-6', checked: false },
    'ZOMBIE': { label: 'Zombie', qColor: 'green-6', checked: false },
  },
  ELEMENTAL_TYPE_1: {
    'WATER': { label: 'Water', qColor: 'blue-6', checked: false },
    'EARTH': { label: 'Earth', qColor: 'green-6', checked: false },
    'PLANT': { label: 'Plant', qColor: 'green-8', checked: false },
    'FIRE': { label: 'Fire', qColor: 'red', checked: false },
    'ELECTRIC': { label: 'Electric', qColor: 'yellow', checked: false },
    'POISON': { label: 'Poison', qColor: 'purple-13', checked: false },
    'ICE': { label: 'Ice', qColor: 'blue-6', checked: false },
    'AIR': { label: 'Air', qColor: 'light-blue-11', checked: false },
  },
  ELEMENTAL_TYPE_2: {
    'NONE': { label: 'None', qColor: 'grey-5', checked: false },
    'WATER': { label: 'Water', qColor: 'blue-6', checked: false },
    'EARTH': { label: 'Earth', qColor: 'green-6', checked: false },
    'PLANT': { label: 'Plant', qColor: 'green-8', checked: false },
    'FIRE': { label: 'Fire', qColor: 'red', checked: false },
    'ELECTRIC': { label: 'Electric', qColor: 'yellow', checked: false },
    'POISON': { label: 'Poison', qColor: 'purple-13', checked: false },
    'ICE': { label: 'Ice', qColor: 'blue-6', checked: false },
    'AIR': { label: 'Air', qColor: 'light-blue-11', checked: false },
  },
  MORAL_ALIGNMENT: {
    'LAWFUL_GOOD': { label: 'Lawful Good', qColor: 'green-8', checked: false },
    'LAWFUL_NEUTRAL': { label: 'Lawful Neutral', qColor: 'blue-6', checked: false },
    'LAWFUL_EVIL': { label: 'Lawful Evil', qColor: 'red-7', checked: false },
    'NEUTRAL_GOOD': { label: 'Neutral Good', qColor: 'green-8', checked: false },
    'TRUE_NEUTRAL': { label: 'True Neutral', qColor: 'blue-6', checked: false },
    'NEUTRAL_EVIL': { label: 'Neutral Evil', qColor: 'red-7', checked: false },
    'CHAOTIC_GOOD': { label: 'Chaotic Good', qColor: 'green-8', checked: false },
    'CHAOTIC_NEUTRAL': { label: 'Chaotic Neutral', qColor: 'blue-6', checked: false },
    'CHAOTIC_EVIL': { label: 'Chaotic Evil', qColor: 'red-7', checked: false },
  },
}

export const battleStatsFilterData: BattleStatsFilterData = {
  'HEALTH':        { value: { min: 1, max: 10 }, label: 'Health', qColor: 'red-7' },
  'STRENGTH':      { value: { min: 1, max: 10 }, label: 'Strength', qColor: 'pink-7' },
  'DEFENSE':       { value: { min: 1, max: 10 }, label: 'Defense', qColor: 'purple-6' },
  'DEXTERITY':     { value: { min: 1, max: 10 }, label: 'Dexterity', qColor: 'deep-purple-5' },
  'WISDOM':        { value: { min: 1, max: 10 }, label: 'Wisdom', qColor: 'indigo-5' },
  'LUCK':          { value: { min: 1, max: 10 }, label: 'Luck', qColor: 'blue-6' },
  'CONSTITUTION':  { value: { min: 1, max: 10 }, label: 'Constitution', qColor: 'light-blue-6' },
  'INTELLIGENCE':  { value: { min: 1, max: 10 }, label: 'Intelligence', qColor: 'cyan-6' },
  'CHARISMA':      { value: { min: 1, max: 10 }, label: 'Charisma', qColor: 'green-6' },
  'WILLPOWER':     { value: { min: 1, max: 10 }, label: 'Willpower', qColor: 'green-8' },
  'MAGIC_ATTACK':  { value: { min: 1, max: 10 }, label: 'Magic Atack', qColor: 'green-9' },
  'MAGIC_DEFENSE': { value: { min: 1, max: 10 }, label: 'Magic Defense', qColor: 'green-10' },
}
