































































// components
import Carousel from '@/components/Home/Carousel.vue'
import CopywriteFooter from '@/components/CopywriteFooter.vue'
import ExpansionItem from '@/components/Home/ExpansionItem.vue'
import TitleLogo from '@/components/TitleLogo.vue'

// utils
import { BASE_URL } from "@/main"
import { faqData } from '@/data/faqData'

// vue
import { Component, Vue } from 'vue-property-decorator'

// vuex
import { stateUtilsModule } from "@/store"

@Component({ 
  components: {
    'carousel': Carousel,
    'copywrite-footer': CopywriteFooter,
    'expansion-item': ExpansionItem,
    'title-logo': TitleLogo
  }
})
export default class Home extends Vue {
  // data
  BASE_URL: string = BASE_URL
  faqData = faqData
  showMainContent = false
  showLanding = true
  progressBar: number = 0
  progressTo100: number = 0

  loadMainContent() {
    this.showLanding = false
    this.showMainContent = true
  }

  loadProgressBar() {
    setTimeout(() => {
      // QLinearProgress bar goes from 0 to 1
      if (this.progressBar < 1) {
        this.progressBar += 0.01
        this.progressTo100 = this.progressBar * 100
        this.loadProgressBar()
      } else {
        setTimeout(() => {
          this.loadMainContent()
          stateUtilsModule.setHasShownLoadingAnimation(true)
        }, 500)
      }
    }, 10)
  }

  mounted() {
    // this.loadMainContent() <- used for development
    
    // start loading bar if it hasn't been shown yet
    if (!stateUtilsModule.getHasShownLoadingAnimation) {
      this.loadProgressBar()
    } else {
      // else go straight to main content
      this.loadMainContent()
    }
  }
}
