import { FAQData } from '@/types/faq'

export const faqData: FAQData = {
  'What are Chonky Chickens?': [
    {
      q: 'I\'m confused, what\'s going on here?',
      a: 'You\'re on your computer or phone and looking at a website.'
    },
    {
      q: 'That\'s not what I meant. What are Chonky Chickens?',
      a: 'It\'s (probably) the first collateralized non-fungible token swap featuring randomized metadata paired with the chonkiest chickens on the interweb.'
    },
    {
      q: 'Oh God, none of that makes any sense. Can you explain like I\'m five years old?',
      a: 'We\'ve created 10,000 unique chicken NFTs with randomly assorted attributes and battle stats. Once minted, you can use them as a profile photo, sell them on an art marketplace, or enter them into games featured on this site to try to win a better NFT! Also, because of how the games are designed NFTs will get destroyed and the collection will become smaller over time!'
    }
  ],
  'What games can I play with them?': [
    {
      q: 'Tell me more about the games? Are there prizes?',
      a: 'There are two games: Playing Chicken and Matchmaker. And yes, there are prizes! Unlike other NFT collections whose rarity is based purely on luck during the minting process, attaining legendary Chonky Chickens will require skill!'
    //  <span class="game-link" @click="$router.push('/playing_chicken')">Playing Chicken</span>
    //  <span class="game-link" @click="$router.push('/matchmaker')">Matchmaker Game</span>
    },
    {
      q: 'What is Playing Chicken?',
      a: 'Put your Chicken up against another player\'s chicken. Every 12 hours (12PM EST and 12AM EST) there is a game to see who can come closest to guessing the price of BTC in USD without going over. All guesses must be submitted at least one hour before the end of the game. Winner takes home their original NFT along with a new rarer Chonky Chicken NFT, all the other players loose their Chonky Chicken NFTs forever.',
    },
    {
      q: 'Seems like the stakes are pretty high. What\'s the Matchmaker game?',
      a: 'The Matchmaker game is not without its risks as well. Submit two chickens into the game and get a randomly selected chicken back out of the prize pool. The only guarantee is that the prize chicken won\'t be a lower tier than it\'s lowest parent. The entered chickens will be destroyed forever, so make sure you can part with your super cute chickens before playing!',
      b: [
        'For example: Submit a tier 4 chicken and a tier 2 chicken and their offspring will always be tier 2 or higher. Chickens of higher tiers have better battle stats and generally look cool as hell.'
      ]
    },
    {
      q: 'Okay fine, I\'m in! How do I get started?',
      a: 'Here is a step by step guide:',
      b: [
        'Buy Ether from a reputable crypto exchange like Binance, Coinbase, Kraken, etc.',
        'Transfer those Ether coins out of that account and into a Wallet browser extension like MetaMask.',
        'Once the coins are in the wallet, visit our website, connect your wallet by clicking the button in the upper right corner, mint a Chicken NFT OR buy one off an art marketplace.',
        'Visit the game pages and follow the instructions to play the games.'
      ]
    },
    {
      q: 'Why do the Chickens have battle stats, classes, and elemental types?',
      a: 'There are currently no third party games that support these Chickens, but the metadata has been generated for other games to use our NFTs.'
    }
  ],
  'What other questions do people have?': [
    {
      q: 'I want to sell my Chicken NFT but I noticed there is a 10% reseller\'s fee. Why?',
      a: 'These Chickens don\'t feed themselves. That and server costs.'
    },
    {
      q: 'I\'ve lost my NFT because I made poor life choices. Can I get it back?',
      a: 'No. But there are many Chickens in the sea.'
    },
    {
      q: 'Will you make more Chickens after the initial 10,000 have been minted?',
      a: 'Probably not. But there might be surprises for long term holders.'
    },
    {
      q: 'Is there anyone I can contact about collaboration, found glitches, or to profess my love for Chickens?',
      a: "Of course! Reach out to us on <a rel='noreferrer' style='color: #FF00C1' aria-label='twitter' href='https://twitter.com/ChonkyChickens' target='_blank'>Twitter</a>, join our Discord, or email us at <a rel='noopener noreferrer' style='color: #FF00C1' aria-label='send email' href='mailto: contact@chonkychickens.com' target='_blank'>contact@chonkychickens.com</a>."
      // ^ add Discord
    }
  ]
}