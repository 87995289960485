import { render, staticRenderFns } from "./FilterExpansionSlider.vue?vue&type=template&id=150ff5bc&scoped=true&"
import script from "./FilterExpansionSlider.vue?vue&type=script&lang=ts&"
export * from "./FilterExpansionSlider.vue?vue&type=script&lang=ts&"
import style0 from "./FilterExpansionSlider.vue?vue&type=style&index=0&id=150ff5bc&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150ff5bc",
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QRange from 'quasar/src/components/range/QRange.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QIcon,QList,QItem,QRange});
