



























































































































// utils
import { BASE_URL } from "@/main"

// vue
import { Component, Vue } from "vue-property-decorator"

// vuex
import { stateUtilsModule, stateGallery } from "@/store"
import UtilsModule from "@/store/UtilsModule"
import GalleryModual from "@/store/Gallery"

@Component
export default class ChickenDetails extends Vue {
  // data
  BASE_URL: string = BASE_URL

  // vuex
  stateUtilsModule: UtilsModule = stateUtilsModule
  stateGallery: GalleryModual = stateGallery

  get chxData() {
    return stateGallery.getSelectedChx
  }

  get tierTitle() {
    switch (this.chxData!.TIER_NUMBER) {
      case "1":
        return "Common Chicken";
      case "2":
        return "Special Chicken";
      case "3":
        return "Rare Chicken";
      case "4":
        return "Champion Chicken";
      case "5":
        return "Legendary Chicken";
      default:
        return "UNDEFINED";
    }
  }

  setRankImg() {
    return require(`../assets/ranks/rank_${this.chxData!.TIER_NUMBER}.png`)
  }

  setElement1Img() {
    return require(`../assets/elements/element_${
      this.chxData!.ELEMENTAL_TYPE_1
    }.png`)
  }

  setElement2Img() {
    if (this.chxData!.ELEMENTAL_TYPE_2) {
      return require(`../assets/elements/element_${
        this.chxData!.ELEMENTAL_TYPE_2
      }.png`)
    }
  }

  onBackBtn() {
    this.$router.replace(`/gallery`)
  }

  onBuyNFTBtn() {
    // buy dem' chickens!
  }

  async mounted() {
    await stateGallery.action_fetchTargetChxById(this.$route.params.id)

    // force scroll to top
    document.querySelector(".component-wrapper")!.scrollIntoView(true)
  }
}
