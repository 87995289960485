
























































// components
import ChickenDetails from '@/views/ChickenDetails.vue'
import MiniCard from '@/components/Gallery/MiniCard.vue'
import Pagination from '@/components/Gallery/Pagination.vue'
import SidePanelFilter from '@/components/Gallery/SidePanelFilter.vue'

// vue
import { Component, Vue } from 'vue-property-decorator'

// vuex
import { stateGallery, stateSearchFilters } from '@/store'
import GalleryModual from '@/store/Gallery'
import SearchFiltersModual from '@/store/SearchFilters'

@Component({
  components: {
    'chicken-details': ChickenDetails,
    'mini-card': MiniCard,
    'pagination': Pagination,
    'side-panel-filter': SidePanelFilter,
  }
})
export default class Gallery extends Vue {
  // data
  drawer: boolean = true
  drawerMode: 'mobile' | 'desktop' = 'desktop'

  // vuex
  stateGallery: GalleryModual = stateGallery
  stateSearchFilters: SearchFiltersModual = stateSearchFilters

  onWindowResize() {
    const viewportWidth = document.getElementById('gallery-section')!.getBoundingClientRect().width
    if (viewportWidth >= 500) this.drawerMode = 'desktop'
    else this.drawerMode = 'mobile'
  }

  closeDrawer() {
    this.drawer = false
  }

  openDrawer() {
    this.drawer = true
  }

  async mounted() {
    this.onWindowResize()
    !Object.keys(stateGallery.getAllMiniCards).length && await stateGallery.action_loadInitChxData()
  }
}
