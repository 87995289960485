







































// utils
import { BASE_URL } from "@/main";

// vue
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Carousel extends Vue {
  // data
  autoplay: boolean = true
  BASE_URL: string = BASE_URL
  slide: number = 1

}
