// components
import UtilsModule from './UtilsModule'
import Gallery from './Gallery'
import SearchFilters from './SearchFilters'

// utils
import localforage from 'localforage'
import VuexPersistence from 'vuex-persist'

// vue
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: localforage as any,
  asyncStorage: true,
  modules: [],
  // modules: [ 'Gallery' ],
});

export const store: any = new Vuex.Store({
  // strict: process.env.DEV === 'true',
  state: {},
  modules: {},
  plugins: [ vuexLocal.plugin ],
})

export const stateSearchFilters = new SearchFilters({ store, name: 'SearchFilters' })
export const stateUtilsModule = new UtilsModule({ store, name: 'UtilsModule' })
export const stateGallery = new Gallery({ store, name: 'Gallery' }, stateSearchFilters)
