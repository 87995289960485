














































































































































































// componentes
import FilterExpansionCheckbox from './FilterExpansionCheckbox.vue'
import FilterExpansionSlider from './FilterExpansionSlider.vue'

// data
import { battleStatsFilterData, checkboxFilterData } from '@/data/filterData'
import { BattleStatsFilterData, CheckboxFilterData } from '@/types/filters'

// vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// vuex
import { stateGallery, stateSearchFilters } from '@/store'
import GalleryModule from '@/store/Gallery'
import SearchFiltersModule from '@/store/SearchFilters'

@Component({
  components: {
    'filter-expansion-checkbox': FilterExpansionCheckbox,
    'filter-expansion-slider': FilterExpansionSlider
  }
})
export default class SidePanelFilter extends Vue {
  @Prop(String) drawerMode!: 'mobile' | 'desktop'

  // data
  searchText: string = ''
  showSidePanel: boolean = true

  // vuex
  stateGallery: GalleryModule = stateGallery
  stateSearchFilters: SearchFiltersModule = stateSearchFilters

  // static data
  checkboxFilterData: CheckboxFilterData = checkboxFilterData
  battleStatsFilterData: BattleStatsFilterData = battleStatsFilterData

  handleClearBtn() {
    if (stateSearchFilters.getFiltersAreActive) stateGallery.action_onClearBtn()
  }

  handleCloseBtn() {
    this.$emit('close')
  }
}
