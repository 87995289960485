






// vue
import { Component, Vue } from 'vue-property-decorator'

@Component({ 
  components: {
    'copywrite-footer': CopywriteFooter,
  }
})
export default class CopywriteFooter extends Vue {}
