



















// vue
import { Component, Vue } from 'vue-property-decorator'

// vuex
import { stateGallery, stateSearchFilters } from '@/store'
import GalleryModual from '@/store/Gallery'
import SearchFiltersModual from '@/store/SearchFilters'

@Component
export default class Pagination extends Vue {
  // vuex
  stateGallery: GalleryModual = stateGallery
  stateSearchFilters: SearchFiltersModual = stateSearchFilters

  async onChange(val: number) {
    if (stateSearchFilters.getCurrentPaginationPage !== val) {
      stateSearchFilters.action_setCurrentPaginationPage(val)
      const ids = stateSearchFilters.getPaginationIdsByPageNum(val)
      await stateGallery.action_fetchChxsByIds(ids)
    }
  }
}
