// components
import ChickenDetails from '../views/ChickenDetails.vue'
import PlayingChicken from '../views/PlayingChicken.vue'
import MatchmakerGame from '../views/MatchmakerGame.vue'
// import Contact from '../views/Contact.vue'
// import FAQ from '../views/FAQ.vue'
import Gallery from '../views/Gallery.vue'
import Home from '../views/Home.vue'

// vue
import VueRouter, { RouteConfig } from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'HOME',
    component: Home
  },
  {
    path: '/gallery',
    name: 'GALLERY',
    component: Gallery,
  },
  {
    path: '/chicken/:id',
    name: 'CHONK DETAILS',
    component: ChickenDetails,
  },
  {
    path: '/playing_chicken',
    name: 'PLAYING CHICKEN',
    component: PlayingChicken
  },
  {
    path: '/matchmaker',
    name: 'MATCHMAKER',
    component: MatchmakerGame
  },
  // {
  //   path: '/faq',
  //   name: 'FAQ',
  //   component: FAQ
  // },
  // {
  //   path: '/contact',
  //   name: 'CONTACT US',
  //   component: Contact
  // },
]

// Create 404 redirect or page doesn't exist component/page

const router = new VueRouter({
  routes,
})

export default router
