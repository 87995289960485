

























































// components
import CopywriteFooter from '@/components/CopywriteFooter.vue'
import TitleLogo from '@/components/TitleLogo.vue'

// vue
import { Component, Vue } from 'vue-property-decorator'

@Component({ 
  components: {
    'copywrite-footer': CopywriteFooter,
    'title-logo': TitleLogo
  }
})
export default class MatchmakerGame extends Vue {}
