import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=3298b1bc&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"
import style0 from "./Home.vue?vue&type=style&index=0&id=3298b1bc&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3298b1bc",
  null
  
)

export default component.exports
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLinearProgress,QBtn,QCard});
