import { render, staticRenderFns } from "./ChickenDetails.vue?vue&type=template&id=5441bda6&scoped=true&"
import script from "./ChickenDetails.vue?vue&type=script&lang=ts&"
export * from "./ChickenDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ChickenDetails.vue?vue&type=style&index=0&id=5441bda6&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5441bda6",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn});
